import React, { useState, useEffect } from 'react';
import { Table, Row, Card, Col } from 'reactstrap';
import { LineChart, Line } from 'recharts';
import { useHistory } from 'react-router-dom';
import { result, sample, sampleSize } from 'lodash';
import moment from 'moment';

import { CircularProgress } from '@material-ui/core';

import { Footer } from '../components/Footer';
import { updown } from '../components/common';

import { MarketHubServiceClient } from './markethubpb/markethub_grpc_web_pb';
import { TickerRequest } from './markethubpb/markethub_pb';
import BinanceService from '../../../ApiService/BinanceService';
import { numberWithCommas } from '../components/common';


const exchangeGatewayUrl = window['config'].apiBaseUrl.exchange;
const client = new MarketHubServiceClient(exchangeGatewayUrl);
const precision = 2;

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const headers = 'Ticker, Name, Last Price, 24h Change, 24 High, 24h Low, Market Cap, 24h Volume'.split(
  ','
);

const sampleTopCard = (label, value, delta, volume) => (
  <Col sm="4">
    <Card body>
      <Row>
        <Col sm="6">
          <h3>{label}</h3>
          <h2 style={{ color: 'limegreen' }}>{numberWithCommas(Number(value), precision)}</h2>
        </Col>
        <Col sm="6">
          <LineChart width={100} height={50} data={data}>
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#8884d8"
              strokeWidth={2}
            />
          </LineChart>
        </Col>
      </Row>
      <div className="m-t-5" style={{ textAlign: 'right' }}>
        <h5>
          <span style={{ color: 'red' }}>{numberWithCommas(Number(delta), precision) + '%'}</span>{' '}
          <span>Volume: {numberWithCommas(Number(volume), precision)} USDT</span>
        </h5>
      </div>
    </Card>
  </Col>
);

const number = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const StockList = ({ path, timestamp }) => {
  const history = useHistory();
  const [market, setMarket] = useState({ stocks: [] });
  const [cryptoMarketData, setCryptoMarketData] = useState([]);

  console.log('MARKET', market);

  const grpcStreaming = () => {
    console.log('streaming started');
    setMarket({ stocks: [] });

    const request = new TickerRequest();
    const stream = client.subscribeFullMarket(request, {});

    stream.on('data', (response) => {
      // MarketUpdate
      const data = response && response.toObject();
      console.log('streaming data', data);
      if (data) {
        // replace existing entry with the updated ones
        data.allTickersList.forEach((stock) => {
            market.stocks = market.stocks.filter(
              (x) => x.symbol !== stock.symbol
            );
  
            var result = stock.symbol.split(".");
            if (result.length >= 3)
            {
              if (parseInt(result[1]) >= 11)
                  market.stocks = market.stocks.concat([stock]);
            }
          });
        market.stocks = market.stocks.sort((a, b) => {
          if (a.symbol < b.symbol) {
            return -1;
          }
          if (a.symbol > b.symbol) {
            return 1;
          }
          return 0;
        });
        setMarket(market);
      }
    });

    stream.on('end', () => {
      console.log('streaming ended');
    });

    console.log('streaming ready');
    return stream;
  };

  useEffect(() => {
    const stream = grpcStreaming();
    return () => {
      stream.cancel();
      console.log('streaming cancelled');
    };
  }, []);

  const { stocks } = market;

  const retrieveCryptoMarketData = async () =>
  {
      const symbols = ['BTCUSDT', 'ETHUSDT', 'EOSUSDT'];
      const crypto = [];
      const promises = symbols.map(
          (value, index, array) =>
          {
              return BinanceService.get24hr(value).then(
                  (result) =>
                  {
                      crypto.push(
                          {
                              symbol: result.data.symbol,
                              priceChangePercent: result.data.priceChangePercent,
                              volume: result.data.volume,
                              lastPrice: result.data.lastPrice
                          }
                        );            
                  }
                ).catch(
                    (err) =>
                    {
                        console.error(
                          'Failed to get crypto market data (' +
                            value + '), error: ' + err);
                    }
                );
          }
      );

      await Promise.all(promises);

      if (crypto.length > 0)
      {
        setCryptoMarketData(crypto);
      }
  }

  useEffect(() => {
      retrieveCryptoMarketData();
  }, []);

  const findCryptoMarketData = (symbol, marketData) =>
  {
      var results = marketData.filter((md) =>
      {
          return md.symbol == symbol;
      })

      if (results.length > 0)
      {
        return results[0];
      }
      else
      {
        return null;
      }
  };

  var MESSAGES = [
    'New 24h High ' + moment(new Date()).format('LTS'),
    'In 5 min ' + moment(new Date()).format('LTS'),
  ];

  return (
    <Card body>
      <div className="text-right">
        Last updated on {moment(timestamp).format('llll')}
      </div>
      <Row>
        {
            findCryptoMarketData('BTCUSDT', cryptoMarketData) == null ?
            sampleTopCard('BTC / USDT', 0, 0, 0) :
            sampleTopCard(
                'BTC / USDT',
                findCryptoMarketData('BTCUSDT', cryptoMarketData).lastPrice,
                findCryptoMarketData('BTCUSDT', cryptoMarketData).priceChangePercent,
                findCryptoMarketData('BTCUSDT', cryptoMarketData).volume)
        }
        {
            findCryptoMarketData('ETHUSDT', cryptoMarketData) == null ?
            sampleTopCard('ETH / USDT', 0, 0, 0) :
            sampleTopCard(
                'ETH / USDT',
                findCryptoMarketData('ETHUSDT', cryptoMarketData).lastPrice,
                findCryptoMarketData('ETHUSDT', cryptoMarketData).priceChangePercent,
                findCryptoMarketData('ETHUSDT', cryptoMarketData).volume)
        }
        {
            findCryptoMarketData('EOSUSDT', cryptoMarketData) == null ?
            sampleTopCard('EOS / USDT', 0, 0, 0) :
            sampleTopCard(
                'EOS / USDT',
                findCryptoMarketData('EOSUSDT', cryptoMarketData).lastPrice,
                findCryptoMarketData('EOSUSDT', cryptoMarketData).priceChangePercent,
                findCryptoMarketData('EOSUSDT', cryptoMarketData).volume)
        }                
      </Row>
      <Table hover>
        <thead>
          <tr>
            {headers.map((x) => (
              <th key={x}>{x}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {stocks.length > 0 ? (
            stocks.map((x) => (
              <tr
                key={x.symbol}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push(`${path}/trade/${x.symbol}`);
                }}
              >
                <th scope="row">{x.symbol}</th>
                <td>{x.name}</td>
                <td>{number.format(x.lastPrice)}</td>
                <td>{updown(x.change24h.toString() + '%')}</td>
                <td>{number.format(x.high24h)}</td>
                <td>{number.format(x.low24h)}</td>
                <td>{number.format(x.marketCap / 1000000) + 'M'}</td>
                <td>{number.format(x.volume24h)}</td>
              </tr>
            ))
          ) : (
            <CircularProgress />
          )}
        </tbody>
      </Table>

      <Footer>
        <div className="text-right" style={{ overflow: 'scroll' }}>
          {sampleSize(stocks, 6).map((x) => {
            return (
              <React.Fragment key={x.symbol}>
                <span className="m-l-30" sm="4" style={{ color: 'gray' }}>
                  <b>{x.symbol}</b> {updown('+7.44%')}{' '}
                  <span style={{ color: 'silver' }}>{sample(MESSAGES)}</span>
                </span>
                <span>{'  ---  '}</span>
              </React.Fragment>
            );
          })}
        </div>
      </Footer>
    </Card>
  );
};
